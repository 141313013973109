import React from "react";

import classes from "./Screenshots.module.css";
import img1 from "../../../assets/imgs/screenshots/1.png";
import img2 from "../../../assets/imgs/screenshots/2.png";
import img3 from "../../../assets/imgs/screenshots/3.png";
import img4 from "../../../assets/imgs/screenshots/4.png";

const Screenshots = () => {
  return (
    <div className={classes.container}>
      <h1 className={classes.Title}>Comment ça marche ?</h1>
      <div className={classes.imageContainer}>
        <div className={classes.Item}>
          <img src={img1} alt="" className={classes.image} />
          <img src={img2} alt="" className={classes.image} />
        </div>
        <div className={classes.Item}>
          <img src={img3} alt="" className={classes.image} />
          <img src={img4} alt="" className={classes.image} />
        </div>
      </div>
    </div>
  );
};

export default Screenshots;

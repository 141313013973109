import React from "react";

import AppStore from "../../../assets/imgs/app-store.png";
import PlayStore from "../../../assets/imgs/play-store.png";
import classes from "./Header.module.css";
import Logo from "../../../assets/imgs/logo.svg";

const Header = () => {
  return (
    <div id="#header" className={classes.Header}>
      <div className={classes.Left}>
        <div className={classes.LogoContainer}>
          <img src={Logo} className={classes.Logo} alt="" />
        </div>
        <div className={classes.Title}>La meilleure solution</div>
        <div className={classes.SubTitle}>
          <ul>
            <li>
              pour gérer facilement et efficacement les commandes, réservations
              et livraisons de la clientèle ;
            </li>
            <li>
              pour commander ou faire une réservation ou encore de vérifier la
              disponibilité d'un article, un produit ou un service.
            </li>
          </ul>
        </div>
        <p style={{ color: "#ccc", marginTop: "25px", paddingRight: "15px" }}>
          Le site est toujours en construction
        </p>
        <div className={classes.StoresContainer}>
          <div className={classes.ImageContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=tech.cladjen.comraav"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={PlayStore} className={classes.Store} alt="" />
            </a>
          </div>
          <div className={classes.ImageContainer}>
            <a
              href="https://apps.apple.com/app/comraav/id1619412686"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStore} className={classes.Store} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.Right} />
    </div>
  );
};

export default Header;

import React from "react";

import classes from "./Footer.module.css";
import logo from "../../../assets/imgs/logo.svg";
import whatsapp from "../../../assets/imgs/whatsapp.svg";
import email from "../../../assets/imgs/email.svg";
import location from "../../../assets/imgs/location.svg";
import facebook from "../../../assets/imgs/facebook.svg";
import linkedin from "../../../assets/imgs/linkedin.svg";

const Footer = () => (
  <div className={classes.Footer}>
    <img src={logo} className={classes.Logo} alt="" />
    <div
      style={{
        marginBottom: "10px",
        color: "#52a456",
        fontSize: "1.1rem",
        textAlign: "center",
        marginTop: "15px",
      }}
    >
      Rassurez-vous à l'avance !
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "35px" }}>
      <img src={location} style={{ width: "15px" }} alt="" />
      <span
        style={{ fontSize: ".9rem", marginLeft: "12px", textAlign: "center" }}
      >
        Ouagadougou, Burkina Faso
      </span>
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "15px" }}>
      <img src={whatsapp} style={{ width: "20px" }} alt="" />
      <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>
        (+226) 71-59-59-29
      </span>
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "15px" }}>
      <img src={email} style={{ width: "20px" }} alt="" />
      <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>
        info@comraav.com
      </span>
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "15px" }}>
      <a
        href="https://www.facebook.com/comraav"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#394241",
          textDecoration: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={facebook} style={{ width: "20px" }} alt="" />
        <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>Facebook</span>
      </a>
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "15px" }}>
      <a
        href="https://www.linkedin.com/company/comraav"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#394241",
          textDecoration: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={linkedin} style={{ width: "20px" }} alt="" />
        <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>LinkedIn</span>
      </a>
    </div>
    <div style={{ marginTop: "40px", color: "#394241", textAlign: "center" }}>
      © CLADJEN {new Date().getFullYear()} - Tous droits réservés
    </div>
  </div>
);

export default Footer;
